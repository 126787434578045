<template>
  <div
    v-if="isNotes && !isQuickstart && !isPinboard"
    style="width: 100%"
  >
    <!-- MOBILE -->
    <div
      v-if="$vuetify.breakpoint.mobile"
    >
      <v-container
        v-if="!selected"
        fluid
        fill-height
        class="pa-0 ml-n4"
      >
        <v-card
          min-height="150"
          width="100%"
          flat
        >
          <v-card-subtitle
            v-if="!reversedNotes.length"
            class="scroll"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              {{ mdiNotebookEditOutline }}
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('expressions|no_notes_available') }}</span>
          </v-card-subtitle>
          <div
            v-if="reversedNotes.length"
            class="pa-0"
          >
            <v-list
              min-height="150"
              :max-height="($vuetify.breakpoint.height - 530).toString()"
              width="100%"
              color="white"
              style="overflow-y: auto;"
              class="mb-4 pa-0"
            >
              <v-list-item-group
                v-model="selected"
              >
                <template v-for="(note, index) in reversedNotes">
                  <v-list-item
                    :key="index"
                    :value="note"
                    active-class="blue--text text--accent-4"
                  >
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ (index + 1) }}. {{ note.value }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon
                          @click.stop="deleteNote(note)"
                        >
                          mdi-trash-can
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
          <v-card-actions
            class="justify-center"
          >
            <v-btn
              rounded
              outlined
              x-small
              color="primary"
              class="pa-3"
              style="position: absolute; bottom: 0px;"
              @click="createNewNote('mobile')"
            >
              {{ $t('actions|add_new_note') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
      <v-container v-else>
        <v-card
          v-touch="{
            left: () => swipe('Left'),
            right: () => swipe('Right'),
            up: () => swipe('Up'),
            down: () => swipe('Down')
          }"
          min-height="180"
          class="mx-n6"
        >
          <v-card-title
            class="font-weight-light justify-center pn-n2"
          >
            <v-icon class="mr-2">
              mdi-note-text
            </v-icon>
            {{ $t('common|note') }} {{ foundIndex }}
          </v-card-title>
          <v-card-text
            v-model="selected"
            class="mt-n3"
            style="font-size: 18px;"
          >
            {{ selected.value }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              small
              color="primary"
              @click="openNote('mobile')"
            >
              {{ $t('actions|open') }}
            </v-btn>
            <v-icon
              style="position: absolute; right: 15px;"
              @click="deleteNote(selected)"
            >
              mdi-trash-can
            </v-icon>
          </v-card-actions>
        </v-card>
        <div class="mt-2">
          <v-btn
            text
            small
            color="primary"
            @click="selected = ''"
          >
            {{ $t('common|list') }}
          </v-btn>
          <v-btn
            text
            small
            color="primary"
            @click="selectPreviousNote"
          >
            {{ $t('common|previous') }}
            <v-icon
              x-small
              class="ml-1"
            >
              mdi-undo
            </v-icon>
          </v-btn>
          <v-btn
            text
            small
            color="primary"
            @click="selectNextNote"
          >
            {{ $t('common|next') }}
            <v-icon
              x-small
              class="ml-1"
            >
              mdi-redo
            </v-icon>
          </v-btn>
        </div>
      </v-container>
    </div>

    <!-- DESKTOP -->
    <div v-else>
      <v-container
        v-if="!selected"
        fluid
        fill-height
      >
        <v-card
          min-height="150"
          width="100%"
          flat
        >
          <v-card-subtitle
            v-if="!reversedNotes.length"
            class="center scroll"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              {{ mdiNotebookEditOutline }}
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('expressions|no_notes_available') }}</span>
          </v-card-subtitle>
          <div v-if="reversedNotes.length">
            <v-list
              min-height="150"
              :max-height="($vuetify.breakpoint.height - 530).toString()"
              width="100%"
              color="white"
              style="overflow-y: auto;"
              class="mb-2"
            >
              <v-list-item-group
                v-model="selected"
              >
                <template v-for="(note, index) in reversedNotes">
                  <v-list-item
                    :key="index"
                    :value="note"
                    active-class="blue--text text--accent-4"
                  >
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ (index + 1) }}. {{ note.value }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon
                          @click.stop="deleteNote(note)"
                        >
                          mdi-trash-can
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
          <v-card-actions
            class="justify-center"
          >
            <v-btn
              rounded
              outlined
              x-small
              color="primary"
              class="pa-3"
              style="position: absolute; bottom: 0px;"
              @click="createNewNote('desktop')"
            >
              {{ $t('actions|add_new_note') }}
            </v-btn>
          </v-card-actions>
          <AddNote
            ref="AddNote"
          />
        </v-card>
      </v-container>
      <v-container v-else>
        <v-card
          height="180"
          class="mx-2"
        >
          <v-card-title
            class="font-weight-light justify-center pn-n2"
          >
            <v-icon class="mr-2">
              mdi-note-text
            </v-icon>
            {{ $t('common|note') }} {{ foundIndex }}
          </v-card-title>
          <v-card-text
            v-if="selected.value.length >= 100"
            v-model="selected"
            class="mt-n3"
            style="font-size: 18px;"
          >
            <span>{{ selected.value.slice(0, 100) + '...' }}</span>
          </v-card-text>
          <v-card-text
            v-else
            v-model="selected"
            class="mt-n3"
            style="font-size: 18px;"
          >
            {{ selected.value }}
          </v-card-text>
          <v-card-actions style="position: absolute; bottom: 1px; width: 95%;">
            <v-btn
              text
              small
              color="primary"
              @click="openNote('desktop')"
            >
              {{ $t('actions|open') }}
            </v-btn>
            <v-btn
              text
              small
              color="primary"
              @click="selected = ''"
            >
              {{ $t('common|list') }}
            </v-btn>
            <v-btn
              text
              small
              color="primary"
              @click="selectNextNote"
            >
              {{ $t('common|next') }}
              <v-icon
                x-small
                class="ml-1"
              >
                mdi-redo
              </v-icon>
            </v-btn>
            <v-icon
              color="error"
              style="position: absolute; right: 15px;"
              @click="deleteNote(selected)"
            >
              mdi-trash-can
            </v-icon>
          </v-card-actions>
          <v-dialog
            v-model="fullNoteDialog"
            persistent
            keydown
            max-width="500px"
            transition="dialog-bottom-transition"
            content-class="vdialognew"
          >
            <FullNoteDialog
              :selected-note="selected"
              :found-index="foundIndex"
              @closeFullNoteDialog="fullNoteDialog = false"
            />
          </v-dialog>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiTrashCanOutline, mdiNotebookEditOutline } from '@mdi/js'
import AddNote from '../../components/dialogs/AddNote.vue'
import FullNoteDialog from './FullNoteDialog.vue'

export default {
  components: { AddNote, FullNoteDialog },
  props: {
    isNotes: {
      type: Boolean,
      default: false
    },
    isQuickstart: {
      type: Boolean,
      default: false
    },
    isPinboard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: '',
      foundIndex: '',
      fullNoteDialog: false,
      icons: {
        trash: mdiTrashCanOutline
      },
      mdiNotebookEditOutline
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    reversedNotes () {
      let _notes = []
      if (this.account) {
        _notes = this.account.myNotes.slice().reverse()
      }
      return _notes
    }
  },
  watch: {
    selected () {
      if (this.selected) {
        const index = this.reversedNotes.findIndex(note => note.value === this.selected.value)
        this.foundIndex = (index + 1).toString()
        return this.foundIndex
      }
    }
  },
  methods: {
    ...mapActions({
      deleteMyNote: 'account/deleteMyNote'
    }),
    swipe (direction) {
      if (direction && direction === 'Left') this.selectNextNote()
      if (direction && direction === 'Right') this.selectPreviousNote()
    },
    myMethod (param) {
      return function (direction, event) {
        console.log(direction, param)
        // do something ~
      }
    },
    createNewNote (input) {
      if (input && input === 'mobile') this.$router.push('/add-note')
      else this.$refs.AddNote.dialog = true
    },
    selectNextNote () {
      const index = this.account.myNotes.findIndex(note => note.value === this.selected.value)
      this.selected = this.account.myNotes[index - 1]
      return this.selected
    },
    selectPreviousNote () {
      const index = this.account.myNotes.findIndex(note => note.value === this.selected.value)
      this.selected = this.account.myNotes[index + 1]
      return this.selected
    },
    openNote (input) {
      if (input && input === 'mobile') {
        this.$router.push({
          name: 'FullNoteMobile',
          params: {
            selectedNote: this.selected,
            foundIndex: this.foundIndex
          }
        })
      } else this.fullNoteDialog = true
    },
    async deleteNote (note) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning'),
        cancelable: true
      })
      if (res) {
        this.loading = true
        const payload = {
          myNotes: note.value
        }
        try {
          await this.deleteMyNote(payload)
          this.selected = ''
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>
  .scroll {
     overflow-y: auto
  }
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
