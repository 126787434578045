<template>
  <v-dialog
    v-model="dialog"
    persistent
    keydown
    max-width="600px"
    transition="dialog-bottom-transition"
    content-class="vdialognew"
  >
    <v-container
      fill-height
      fluid
    >
      <v-card
        width="100%"
      >
        <v-card-title class="font-weight-light pn-n2">
          {{ $t('actions|add_new_note') }}
          <v-spacer />
          <v-icon
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-textarea
          v-model="note"
          :rules="rules"
          counter
          outlined
          :label="$t('actions|type_text_here')"
          class="px-8 mt-4"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            rounded
            dense
            small
            :disabled="disabled"
            min-width="150"
            :loading="loading"
            class="mb-2"
            @click="submit"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      note: '',
      rules: [v => v.length <= 350 || 'Max 350 characters'],
      disabled: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    note (value) {
      if (value.length > 350) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    }
  },
  methods: {
    ...mapActions({
      createMyNote: 'account/createMyNote'
    }),
    reset () {
      this.note = ''
    },
    async submit () {
      this.loading = true
      const payload = {
        myNotes: [
          { value: this.note }
        ]
      }
      try {
        await this.createMyNote(payload)
        this.dialog = false
        this.reset()
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
